import React from "react";
import { LeavesStatus, MySpaceStatus } from "../../utilities/constants";

const getNotifStatus = (role, code) => {
  return role === "TEAM LEAD" || role === "EMPLOYEE"
    ? code === "hr-accept-leave"
      ? MySpaceStatus[1]
      : code === "hr-reject-leave"
      ? MySpaceStatus[2]
      : role === "TEAM LEAD"
      ? code === "emp-accept-negotiation"
        ? LeavesStatus[1]
        : code === "emp-reject-negotiation"
        ? LeavesStatus[3]
        : LeavesStatus[0]
      : role === "EMPLOYEE"
      ? code === "tl-reject-leave"
        ? MySpaceStatus[2]
        : code === "tl-negotiate-leave"
        ? MySpaceStatus[3]
        : code.includes("hr")
        ? MySpaceStatus[0]
        : LeavesStatus[0]
      : {}
    : LeavesStatus[1];
};

export default getNotifStatus;
